import {getCookie, loggedIn} from '../configs/url'
import axios from 'axios'
/**
 * Return if user is logged in
 * This is completely up to you and how you want to store the token in your frontend application
 * e.g. If you are using cookies to store the application please update this function
 */
// eslint-disable-next-line arrow-body-style
export const isUserLoggedIn = () => {
  return  JSON.parse(localStorage.getItem('token')) || getCookie('TOKEN')
}

export const getUser = () => {
  axios.defaults.headers['Authorization'] = `Bearer ${getCookie('TOKEN')}`
  axios.defaults.withCredentials = true
  axios.get(loggedIn).then((response) => {
    localStorage.setItem('user', JSON.stringify(response.data))
  })
}

export const getUserData = async () => {

  localStorage.getItem('user') ? localStorage.getItem('user') :  getUser()
  return JSON.parse(localStorage.getItem('user'))
}
/**
 * This function is used for demo purpose route navigation
 * In real app you won't need this function because your app will navigate to same route for each users regardless of ability
 * Please note role field is just for showing purpose it's not used by anything in frontend
 * We are checking role just for ease
 * NOTE: If you have different pages to navigate based on user ability then this function can be useful. However, you need to update it.
 * @param {String} userRole Role of user
 */
export const getHomeRouteForLoggedInUser = userRole => {

  console.log(userRole)

  if (userRole.includes('superAdmin')) return '/designplace'
  if (userRole === 'admin') return '/'
  if (userRole === 'superAdmin') return '/'
  if (userRole === 'client') return { name: 'access-control' }
  return '/'
}
