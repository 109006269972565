// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
// ** Axios Imports
import axios from 'axios'

export const getData = createAsyncThunk('agencies/all', async params => {
  const response = await axios.get(`agencies`, {params})
  return { allData: response.data.allData, data: response.data, totalPages: response.data.total, params }
})

export const datatablesSlice = createSlice({
  name: 'agencies',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: []
  },
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getData.fulfilled, (state, action) => {
      state.data = action.payload.data
      state.params = action.payload.params
      state.allData = action.payload.allData
      state.total = action.payload.totalPages
    })
  }
})

export default datatablesSlice.reducer
