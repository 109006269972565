// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
// ** Axios Imports
import axios from 'axios'
axios.defaults.withCredentials = true

const endpoint = 'states'
export const getData = createAsyncThunk(`${endpoint  }/all`, async params => {
  axios.defaults.withCredentials = true
  const response = await axios.get(endpoint, {params})
  return { data: response.data.states, params }
})

export const statesSlice = createSlice({
  name: 'states',
  initialState: {
    data: [],
    total: 1,
    params: {}
  },
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getData.fulfilled, (state, action) => {
      state.data = action.payload.data
      state.params = action.payload.params
      state.total = action.payload.totalPages
    })
  }
})

export default statesSlice.reducer
