// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
// ** Axios Imports
import axios from 'axios'
axios.defaults.withCredentials = true

const endpoint = `marketing_plans`

export const all = createAsyncThunk(`${endpoint}/all`, async params => {
  const response = await axios.get(`${endpoint}`, {params})
  return { data: response.data, total: response.data.length, params }
})


export const get = createAsyncThunk(`${endpoint}/get`, async id => {
  const response = await axios.get(`${endpoint}/${id}`, { id })
  return response.data
})

export const add = createAsyncThunk(`${endpoint}/add`, async (item) => {
  axios.defaults.withCredentials = true
  const response = await  axios.post(`${endpoint}`, item)
    return response.data
})

export const update = createAsyncThunk(`${endpoint}/update`, async (item) => {
  axios.defaults.withCredentials = true
  const response = await axios.put(`${endpoint}/${item.id}`, item)
  return response.data
})


export const marketingPlanSlice = createSlice({
  name: endpoint,
  initialState: {
    data: [],
    total: 1,
    params: {},
    item: null,
      status: 'READY'
  },
  reducers: {},
  extraReducers: builder => {
    builder
        .addCase(all.fulfilled, (state, action) => {
          state.data = action.payload.data
          state.params = action.payload.params
          state.total = action.payload.totalPages
            state.status = 'SUCCESS'
        })
        .addCase(get.fulfilled, (state, action) => {
          state.item = action.payload
        })
        .addCase(update.fulfilled, (state, action) => {
          state.item = action.payload
        })
  }
})

export default marketingPlanSlice.reducer
