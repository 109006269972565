// ** Redux Imports
import rootReducer from './rootReducer'
import { configureStore, combineReducers } from '@reduxjs/toolkit'


const combinedReducer = combineReducers(rootReducer)
const appReducer = (state, action) => {

  if (state?.marketStudy?.status === 'SUCCESS_PRESENTATION') { // check for action type
    const prevState = state
    state = {...state, marketStudy: { ...prevState.marketStudy, status: 'READY'} }
  }
  if (state?.marketStudy?.status === 'GENERATE_SUCCESS') { // check for action type
    const prevState = state
    state = {...state, marketStudy: { ...prevState.marketStudy, status: 'READY'} }
  }

  return combinedReducer(state, action)
}

const store = configureStore({
  reducer: appReducer,
  middleware: getDefaultMiddleware => {
    return getDefaultMiddleware({
      serializableCheck: false
    })
  }
})

export { store }
