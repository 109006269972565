// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
// ** Axios Imports
import axios from 'axios'
axios.defaults.withCredentials = true

const endpoint = `market_studies`

export const all = createAsyncThunk(`${endpoint}/all`, async params => {
  const response = await axios.get(`${endpoint}`, { params })
  const stats = await axios.get(`${endpoint}_stats`, { params })
  return {
    data: response.data.data,
    total: response.data.meta.total,
    stats: stats.data,
    params
  }
})


export const get = createAsyncThunk(`${endpoint}/get`, async id => {
  const response = await axios.get(`${endpoint}/${id}`, { id })
  return response.data.data
})

export const remove = createAsyncThunk(`${endpoint}/remove`, async id => {
  await axios.delete(`${endpoint}/${id}`, { id })
})

export const add = createAsyncThunk(`${endpoint}/add`, async (item) => {
  const response = await axios.post(`${endpoint}`, item)
  return response.data
})

export const attachSample = createAsyncThunk(`${endpoint}/attach_sample`, async (item) => {
  const response = await axios.patch(`${endpoint}/attach_sample/${item.id}/${item.sample_id}`)
  return response.data
})

export const detachSample = createAsyncThunk(`${endpoint}/detach_sample`, async (item) => {
  const response = await axios.patch(`${endpoint}/detach_sample/${item.id}/${item.sample_id}`)
  return response.data
})


export const getSamples = createAsyncThunk(`${endpoint}/getSamples`, async id => {
  const response = await axios.get(`${endpoint}/samples/${id}`, { id })
  return { data: response.data }
})


export const update = createAsyncThunk(`${endpoint}/update`, async (item) => {
  const response = await axios.put(`${endpoint}/${item.id}`, item)
  return response.data
})

export const savePresentation = createAsyncThunk(`${endpoint}/presentation_config`, async (item) => {
  const response = await axios.put(`${endpoint}/presentation_configs/${item.id}`, item.presentationSettings)
  return response.data
})
export const saveStatus = createAsyncThunk(`${endpoint}/status`, async (item) => {
  const response = await axios.patch(`${endpoint}/status/${item.id}`, { status: item.status })
  return response.data
})

export const savePrice = createAsyncThunk(`${endpoint}/savePrice`, async (item) => {
  const response = await axios.patch(`${endpoint}/prices/${item.id}`, item)
  return response.data
})

export const getPrices = createAsyncThunk(`${endpoint}/prices`, async (item) => {
  const response = await axios.get(`${endpoint}/prices/${item.id}`)
  return response.data
})

export const getCovers = createAsyncThunk(`${endpoint}/covers`, async () => {
  const response = await axios.get(`/generator/market_study_covers`)
  return response.data
})
export const getTemplates = createAsyncThunk(`${endpoint}/templates`, async () => {

  try {
    const response = await axios.get(`/generator/market_study_templates`)
    return response.data
  } catch (rejected) {
    return rejected
  }
})
export const generatePdf = createAsyncThunk(`${endpoint}/generator`, async (item, { rejectWithValue }) => {

  try {
    const response = await axios.post(`/generator/market_study/${item.id}`, item)
    return response.data
  } catch (err) {
    return rejectWithValue(err.response.data)
  }

})

export const getFeaturesProperty = createAsyncThunk(`${endpoint}/property_features`, async () => {
  try {
    const { data } = await axios.get(`/property_features`)

    return data
  } catch (rejected) {
    return rejected
  }
})


export const marketStudySlice = createSlice({
  name: endpoint,
  initialState: {
    data: [],
    stats: {
      signed: 0,
      presented: 0,
      pending: 0,
      total: 0
    },
    selectedSamples: [],
    total: 0,
    error: null,
    params: {},
    item: null,
    prices: {},
    file: null,
    covers: [],
    templates: [],
    featuresProperty: [],
    status: 'READY'
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(all.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.total
        state.stats = action.payload.stats
        state.status = 'SUCCESS'
      })
      .addCase(all.pending, (state, action) => {
        state.status = 'PENDING'
      })
      .addCase(update.pending, (state, action) => {
        state.status = 'PENDING'
      })
      .addCase(add.pending, (state, action) => {
        state.status = 'PENDING'
      })
      .addCase(remove.pending, (state, action) => {
        state.status = 'REMOVE_PENDING'
      })
      .addCase(remove.fulfilled, (state, action) => {
        state.status = 'REMOVE_SUCCESS'
      })
      .addCase(get.fulfilled, (state, action) => {
        state.item = action.payload
        state.status = 'SUCCESS_ITEM'
      })
      .addCase(get.pending, (state, action) => {
        state.status = 'PENDING'
      })
      .addCase(update.fulfilled, (state, action) => {
        state.status = 'SUCCESS_ADD'
        state.error = null
      })
      .addCase(add.fulfilled, (state, action) => {
        state.item = action.payload
        state.error = null
        state.status = 'SUCCESS_ADD'
      })
      .addCase(add.rejected, (state, action) => {
        state.error = action.payload
        state.status = 'ERROR_ADD'
      })
      .addCase(attachSample.fulfilled, (state, action) => {
        state.status = 'SAMPLE_ADDED'
      })
      .addCase(detachSample.fulfilled, (state, action) => {
        state.status = 'SAMPLE_REMOVED'
      })
      .addCase(getSamples.fulfilled, (state, action) => {
        state.status = 'SAMPLES_LOADED'
        state.selectedSamples = action.payload.data
      })
      .addCase(getSamples.pending, (state, action) => {
        state.status = 'SAMPLES_PENDING'
      })
      .addCase(savePrice.fulfilled, (state, action) => {
        state.status = 'SUCCESS_PRICE'
      })
      .addCase(savePrice.pending, (state, action) => {
        state.status = 'PENDING'
      })
      .addCase(savePrice.rejected, (state, action) => {
        state.status = 'ERROR'
        state.error = action.payload.message
      })
      .addCase(getPrices.fulfilled, (state, action) => {
        state.status = 'GET_PRICES_SUCCESS'
        state.prices = action.payload
      })
      .addCase(getPrices.pending, (state, action) => {
        state.status = 'PENDING'
      })
      .addCase(getPrices.rejected, (state, action) => {
        state.status = 'ERROR'
        state.error = 'Não foi possível carregar os valores percentuais deste estudo'
      })
      .addCase(savePresentation.fulfilled, (state, action) => {
        state.status = 'SUCCESS_PRESENTATION'
      })
      .addCase(savePresentation.pending, (state, action) => {
        state.status = 'PRESENTATION_PENDING'
      })
      .addCase(savePresentation.rejected, (state, action) => {
        state.status = 'ERROR'
        state.error = 'Não foi possível salvar sua apresentação. Tente novamente mais tarde'
      })
      .addCase(getCovers.rejected, (state, action) => {
        state.status = 'ERROR'
        state.error = action.payload
      })
      .addCase(getCovers.fulfilled, (state, action) => {
        state.status = 'COVERS_SUCCESS'
        state.covers = action.payload
      })
      .addCase(getCovers.pending, (state, action) => {
        state.status = 'COVERS_PENDING'
      })
      .addCase(getTemplates.rejected, (state, action) => {
        state.status = 'TEMPLATES_ERROR'
        state.error = action.payload
      })
      .addCase(getTemplates.fulfilled, (state, action) => {
        state.status = 'TEMPLATES_SUCCESS'
        state.templates = action.payload
      })
      .addCase(getTemplates.pending, (state, action) => {
        state.status = 'TEMPLATES_PENDING'
      })
      .addCase(generatePdf.pending, (state, action) => {
        state.status = 'GENERATE_PENDING'
      })
      .addCase(generatePdf.fulfilled, (state, action) => {
        state.status = 'GENERATE_SUCCESS'
        state.file = action.payload
      })
      .addCase(generatePdf.rejected, (state, action) => {
        state.status = 'GENERATE_ERROR'
        state.error = action.payload
      })
      .addCase(getFeaturesProperty.rejected, (state, action) => {
        state.status = 'FEATURES_ERROR'
        state.error = action.payload
      })
      .addCase(getFeaturesProperty.fulfilled, (state, action) => {
        state.status = 'FEATURES_SUCCESS'
        state.featuresProperty = action.payload
      })
      .addCase(getFeaturesProperty.pending, (state, _action) => {
        state.status = 'FEATURES_PENDING'
      })
  }
})

export default marketStudySlice.reducer
