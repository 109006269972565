// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'
axios.defaults.withCredentials = true

const endpoint = 'cities'
export const getCities = createAsyncThunk(`${endpoint  }/all`, async params => {
  axios.defaults.withCredentials = true
  const response = await axios.get(endpoint, {params})
  return { data: response.data, params }
})

export const citiesSlice = createSlice({
  name: 'cities',
  initialState: {
    data: [],
    total: 1,
    params: {}
  },
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getCities.fulfilled, (state, action) => {
      state.data = action.payload.data
      state.params = action.payload.params
      state.total = action.payload.totalPages
    })
  }
})

export default citiesSlice.reducer
