// ** Redux Imports
import {createSlice, createAsyncThunk} from '@reduxjs/toolkit'
// ** Axios Imports
import axios from 'axios'

axios.defaults.withCredentials = true
const endpoint = 'samples'

export const all = createAsyncThunk(`${endpoint}/all`, async (params, {rejectWithValue}) => {
    try {
        const response = await axios.get(`samples`, {params})
        return {data: response.data.data, total: response.data.total, params}
    } catch (err) {
        return rejectWithValue(err.response.data)
    }


})

export const crawler = createAsyncThunk('samples/crawler', async params => {
    const response = await axios.get(`/crawler`, {params})
    return {data: response.data, params}
})

export const get = createAsyncThunk(`${endpoint}/get`, async id => {
    const response = await axios.get(`${endpoint}/${id}`)
    return response.data.data
})
export const remove = createAsyncThunk('samples/remove', async id => {
     await axios.delete(`/samples/${id}`)
})
export const removePicture = createAsyncThunk('samples/removeImage', async id => {
     await axios.delete(`/property_pictures/${id}`)
})

export const addSample = createAsyncThunk('samples/addSample', async (sample, {dispatch, getState}) => {
    await axios.post(`/samples`, sample)
    return sample
})

export const update = createAsyncThunk('samples/update', async (sample) => {
    console.log(sample)
    const response = await axios.put(`/samples/${sample.id}`, sample)
    return response.data
})


export const samplesSlice = createSlice({
    name: 'samples',
    initialState: {
        data: [],
        total: 1,
        params: {},
        selectedSample: null,
        item: null,
        crawler_data: null,
        crawler_status: 'READY',
        crawler_loading: false,
        crawler_error: null,
        status: 'READY',
        error: null
    },
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(all.pending, (state, action) => {
                state.status = 'PENDING'
            })
            .addCase(all.rejected, (state, action) => {
                state.status = 'ERROR'
                state.error = action.payload
            })
            .addCase(remove.pending, (state, action) => {
                state.status = 'REMOVE_PENDING'
            })
            .addCase(remove.fulfilled, (state, action) => {
                state.status = 'REMOVE_SUCCESS'
            })
            .addCase(remove.rejected, (state, action) => {
                state.status = 'REMOVE_ERROR'
            })
            .addCase(addSample.pending, (state, action) => {
                state.status = 'SAVING'
            })
            .addCase(addSample.fulfilled, (state, action) => {
                state.status = 'ADD_SUCCESS'
            })
            .addCase(addSample.rejected, (state, action) => {
                state.status = 'ERROR'
            })
            .addCase(update.pending, (state, action) => {
                state.status = 'SAVING'
            })
            .addCase(update.fulfilled, (state, action) => {
                state.status = 'UPDATE_SUCCESS'
            })
            .addCase(update.rejected, (state, action) => {
                state.status = 'ERROR'
            })
            .addCase(removePicture.pending, (state, action) => {
                state.status = 'REMOVING_PICTURE'
            })
            .addCase(removePicture.fulfilled, (state, action) => {
                state.status = 'PICTURE_REMOVE_SUCCESS'
            })
            .addCase(removePicture.rejected, (state, action) => {
                state.status = 'PICTURE_REMOVE_ERROR'
            })
            .addCase(all.fulfilled, (state, action) => {
                state.data = action.payload.data
                state.params = action.payload.params
                state.total = action.payload.totalPages
                state.status = 'SUCCESS'
            })
            .addCase(get.pending, (state, action) => {
                state.status = 'PENDING'
            })
            .addCase(get.fulfilled, (state, action) => {
                state.item = action.payload
                state.status = 'SUCCESS'
            })
            .addCase(crawler.fulfilled, (state, action) => {
                state.crawler_data = action.payload
                state.crawler_status = 'SUCCESS'
            })
            .addCase(crawler.pending, (state) => {
                state.crawler_loading = true
                state.crawler_status = 'PENDING'
            })
            .addCase(crawler.rejected, (state, action) => {
                state.crawler_status = 'ERROR'
                state.crawler_error = action.error || 'nao deu'
                state.crawler_loading = false
            })
    }
})

export default samplesSlice.reducer
