// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
// ** Axios Imports
import axios from 'axios'
axios.defaults.withCredentials = true

const endpoint = 'pictures'
export const all = createAsyncThunk(`${endpoint  }/all`, async params => {
  const response = await axios.get(endpoint, {params})
  return { data: response.data.data }
})

export const remove = createAsyncThunk(`${endpoint  }/remove`, async id => {
   await axios.delete(`${endpoint}/${id}`)
})


export const statesSlice = createSlice({
  name: 'pictures',
  initialState: {
    data: [],
    item: {},
    status: 'READY',
    error_message: '',
    total: 1,
    params: {}
  },
  reducers: {},
  extraReducers: builder => {
    builder.addCase(all.fulfilled, (state, action) => {
      state.data = action.payload.data
      state.total = action.payload.totalPages
    })
    builder.addCase(remove.pending, (state, action) => {
      state.status = 'REMOVE_PENDING'
    })
    builder.addCase(remove.fulfilled, (state, action) => {
      state.status = 'REMOVE_SUCCESS'
    })
    builder.addCase(remove.rejected, (state, action) => {
      state.status = 'REMOVE_ERROR'
      state.error_message = action.payload
    })
  }
})

export default statesSlice.reducer
