// ** Auth Endpoints
export default {
  loginEndpoint: '/login',
  registerEndpoint: '/register',
  logoutEndpoint: '/logout',

  // ** This will be prefixed in authorization header with token
  // ? e.g. Authorization: Bearer <token>
  tokenType: 'Bearer',
  storageTokenKeyName: 'token'

}
